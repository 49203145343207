import React from 'react';
import {graphql, navigate} from 'gatsby';
import ReactPaginate from 'react-paginate';

import {Box} from '@chakra-ui/core';

import Layout from '../components/layout';
import ArticlesList from '../components/ArticlesList';
import Chevron from '../assets/images/chevron.svg';
import SEO from "../components/SEO";
import {Trans} from "gatsby-plugin-react-i18next";

const seo = {
  title: `Статьи - Evors`,
  noindex: false,
  nofollow: false,
}

export default ({data, pageContext}) => (
  <>
    <SEO seoData={seo}/>
    <Layout language={pageContext.language}>
      <div className={"container mt-sm"}>
        <h1 className={"main-title"}>
          <Trans>articles.title</Trans>
        </h1>

        <ArticlesList articles={data.allWpArticle}/>
      </div>

      {pageContext && pageContext.totalPages > 1 && (
        <Box mt={10}>
          <ReactPaginate
            previousLabel={<img src={Chevron} alt={"previous"}/>}
            nextLabel={<img src={Chevron} alt={"next"}/>}
            onPageChange={({selected}) => {
              const page = selected + 1;
              const path = page === 1 ? `/blog/` : `/blog/${page}/`;
              navigate(path);
            }}
            disableInitialCallback
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageContext.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={pageContext.page - 1}
          />
        </Box>
      )}
    </Layout>
  </>
)

export const query = graphql`
    query Articles($offset: Int!, $perPage: Int!, $language: String!) {
        allWpArticle(
            limit: $perPage
            skip: $offset
            filter: { language: { slug: { eq: $language } } }
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                content
                title
                uri
                date
                featuredImage {
                    node {
                        sourceUrl
                    }
                }
            }
        }
    }
`;
