import React, {Component} from 'react';
import ArticlesListItem from '../ArticlesListItem';

export default class ArticlesList extends Component {
  render() {
    return (
      <div className={"articles"}>
        {this.props.articles.nodes.map(( item, index ) => <ArticlesListItem item={item} key={index} />)}
      </div>
    )
  }
}
