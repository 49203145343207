import React, {useEffect, useState} from 'react';
import ClockGray from '../../assets/images/clock_gray.svg';
import Arrow from '../../assets/images/arrow.svg';
import moment from 'moment';
import {Link} from 'gatsby';
import NewsImg from '../../assets/images/news.png';
import {Trans} from 'gatsby-plugin-react-i18next';

export default function ArticlesListItem({item, index}) {
  const [windowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  let symbolsLength = windowWidth < 1000 ? 125 : 375;

  const image = item.featuredImage
    ? item.featuredImage.node.remoteFile.childImageSharp.fluid.srcSet
    : NewsImg;

  return (
    <div className={'article'}>
      <div className={'article__img'}>
        <img srcSet={image} alt={'article'}/>
      </div>
      <div className={'article__content'}>
        <div>
          <h4 className={'article-title'}
              dangerouslySetInnerHTML={{__html: item.title}}/>

          <div dangerouslySetInnerHTML={{
            __html:
              (item.content && (item.content).length > symbolsLength) ?
                (((item.content).substring(0, symbolsLength - 3)) + '...') :
                item.content,
          }}/>
        </div>

        <div className={'article-footer'}>
          <Link className={'btn btn--link btn-bordered btn--small'} to={item.uri}>
            <Trans>link.view</Trans> <img className={'ic--arrow link-icon'} src={Arrow} alt={'arrow'}/>
          </Link>
          <span className={'article__date'}>
            <img className={'ic--date '} src={ClockGray} alt={'news'}/>
            {moment(item.date).format('DD MMMM YYYY')}
          </span>
        </div>
      </div>
    </div>
  );
}
